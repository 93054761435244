@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.login-container {
  font-family: "Roboto", sans-serif;
  background: #f3e0e2;
  overflow-x: hidden;
  background-image: url("./../assets/img/abstract-room-sun-card-blank.jpg");
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-main-style {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 50px;
  border-radius: 0.7rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.login-img-wrapper {
  height: 50vh;
}

.login-img-wrapper img {
  width: 100%;
  height: 50vh;
}

.btn-primary {
  color: #fff !important;
  background-color: rgb(251, 60, 0) !important;
  border-color: #fff !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: rgb(251, 60, 0) !important;
  border-color: #fff !important;
}

/* login card tasarımı */
.login-wrapper {
  height: 50vh;
  border-radius: 0.7rem;
  padding: 50px;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-wrapper-register {
  height: 85vh;
  border-radius: 0.7rem;
  padding: 50px;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* login card tasarımı */

.login-wrapper .heading img {
  width: 25% !important;
}

.login-wrapper .heading h1 {
  font-weight: 700;
  font-size: 2rem;
  color: #000;
}

.login-wrapper .social {
  padding: 2.5rem;
}

.login-wrapper .social .social-links {
  text-decoration: none;
  color: #000;
  font-size: 1.3rem;
}

.login-wrapper .social .social-links > .fa-brands {
  border: 1px solid #e4e4e4;
  display: inline-block;
  border-radius: 60px;
  padding: 0.5em 0.6em;
  margin-left: 0.5rem;
  transition: all 0.5s ease;
}

.login-wrapper .social .social-links > .fa-brands:hover {
  background: #000;
  color: #fff;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.form-group p {
  font-size: 0.9rem;
  opacity: 0.9;
  color: #212121;
  margin-bottom: 0.3rem;
}
.form-group > input {
  padding: 0.7rem 0.1rem;
  margin-bottom: 0.9rem;
  border: none;
  width: 100%;
  border-radius: 15px;
}
.form-group > input:focus {
  background: #eee;
}
.form-group > ::placeholder {
  background: #eeeeee;
  padding: 0.7rem 0.2rem;
  text-transform: capitalize;
}
.btn {
  font-size: 0.9rem;
  text-decoration: none;
}
.btn-forget {
  color: #212121;
  margin-bottom: 1rem;
}
.btn-primary {
  background: #ff4b2b;
  padding: 0.6rem 2.4rem;
  text-transform: uppercase;
  color: #fff;
  border-radius: 2rem;
  font-weight: 700;
  border: 1px solid #ff4b2b;
  transition: all 200ms linear;
  margin-bottom: 5px;
}
.btn-primary:hover {
  background: #fff;
  color: #ff4b2b;
  border: 1px solid #ff4b2b;
}

@media screen and (max-width: 768px) {
  .login-wrapper-register {
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .login-img-wrapper img {
    display: none;
  }

  .login-img-wrapper {
    display: none;
  }

  .container-main-style {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
  }

  .login-wrapper {
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .login-wrapper-register {
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .heading img {
    width: 65px;
  }

  .account-heading a {
    margin: 0;
  }

  .login-img-wrapper img {
    display: none;
  }

  .login-img-wrapper {
    display: none;
  }

  .container-main-style {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
  }

  .login-wrapper {
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .login-wrapper-register {
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 95vh;
  }

  .container-main-style {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
    padding: 0;
  }
}
